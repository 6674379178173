import http from '../clients/Axios'

export default class LaporanService {
  async PurchaseOrderList(startDate, endDate, supp_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/purchase_list?t=${unixTime}&pr_date=${startDate},${endDate}`
    if (supp_id !== 0) {
      urlPath = urlPath + '&supp_id=' + supp_id
    }
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async PurchaseOrderDetail(startDate, endDate, supp_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/purchase_detail?t=${unixTime}&pr_date=${startDate},${endDate}`
    if (supp_id !== 0) {
      urlPath = urlPath + '&supp_id=' + supp_id
    }
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async HistoryPembelian(filterDate, itemid = 0, supp_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/purchase_history?t=${unixTime}&pr_date=${filterDate}`
    if (itemid !== 0 && itemid !== null) {
      urlPath = urlPath + `&item_id=${itemid}`
    }
    if (supp_id !== 0 && supp_id !== null) {
      urlPath = urlPath + `&supp_id=${supp_id}`
    }
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async DailyReceive(startDate, endDate, supp_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/daily_receive?t=${unixTime}&rcv_at=${startDate},${endDate}`
    if (supp_id !== 0) {
      urlPath = urlPath + '&supp_id=' + supp_id
    }
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async SalesList(startDate, endDate) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/sales_list?t=${unixTime}&sl_at=${startDate},${endDate}`

    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async SalesDetail(startDate, endDate) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/sales_detail?t=${unixTime}&sl_at=${startDate},${endDate}`

    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async Promo(kode) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/promo?t=${unixTime}&kode=${kode}`
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async PromoPenjualan(startDate, endDate, supp_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/promo_penjualan?t=${unixTime}&start_at=${startDate}&end_at=${endDate}`
    if (supp_id !== 0) {
      urlPath = urlPath + '&supp_id=' + supp_id
    }
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async PerubahanHarga(filterDate, supp_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/price_history?t=${unixTime}&rcv_at=${filterDate}&supp_id=${supp_id}`
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }
  async HistoryHargaItem(filterDate, item_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/itemprice_history?t=${unixTime}&created_at=${filterDate}&item_id=${item_id}`
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async SaldoHutang(filterDate, supp_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/payable_balance?t=${unixTime}&trans_date=${filterDate}`
    if (supp_id !== 0) {
      urlPath = urlPath + '&supp_id=' + supp_id
    }
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async SaldoHutangJatuhTempo(filterDate, supp_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/payable_balance_due_at?t=${unixTime}&trans_date=${filterDate}`
    if (supp_id !== 0) {
      urlPath = urlPath + '&supp_id=' + supp_id
    }
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async KartuStok(filterDate, itemid) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/stock_card?t=${unixTime}&DateTrans=${filterDate}&Item_Id=${itemid}`
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async StockSupplier(supp_id, ctg_id = 0, item_id = 0) {
    const unixTime = Math.floor(Date.now() / 1000)
    let urlPath = `/report/stock_supplier?t=${unixTime}&supp_id=${supp_id}&ctg_id=${ctg_id}&item_id=${item_id}`
    return http({
      url: urlPath,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
