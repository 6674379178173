import http from '../clients/Axios'

export default class SupplierService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField
    let filters_param = `page=${pageNumber}&size=${pageSize}&sort=${sort}`

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (el === 'supp' || el === 'name') {
            filters_param = filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(`/supplier/paginate?${filters_param}`)

    return res.data
  }

  async get() {
    const res = await http.get('/supplier')
    return res.data
  }

  async getActive() {
    const res = await http.get('/supplier?active=true')
    return res.data
  }
  async getListActive(keyword = '') {
    const res = await http.get(`/supplier?active=true&name=${keyword}`)
    return res.data
  }

  async getList(keyword = '') {
    const res = await http.get(`/supplier/list?keyword=${keyword}`)
    return res.data
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/supplier${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/supplier', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/supplier/${id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/supplier/${id}`)
    return res
  }
}
